<template>
  <div>
    <b-row class="align-items-end mb-2">
      <b-col
        cols="4"
        class="mb-1 mb-sm-0">
        <SearchTextInput
          v-model="filter.searchCodeAndName"
          label="Search"
          placeholder="ช่องทางการแจ้งเรื่อง"
          @keyup.enter.native="fetchFilter()" />
      </b-col>
      <b-col
        cols="4"
        class="mb-1 mb-sm-0">
        <FilterDropdown
          v-model="filter.userTeamId"
          label="Team"
          placeholder="Team"
          :options="options.userTeam"
          label-option="name"
          return-value="id"
          @input="fetchFilter()" />
      </b-col>
      <b-col
        cols="4"
        class="mb-1 mb-sm-0">
        <FilterDropdown
          v-model="filter.userGroupId"
          label="Group"
          placeholder="Group"
          :options="options.userGroup"
          label-option="name"
          return-value="id"
          @input="fetchFilter()" />
      </b-col>
      <b-col
        class="mb-1 mb-sm-0"
        cols="12"
        md="4">
        <DatePickerFilter
          v-model="filter.startDate"
          label="Start Date" />
      </b-col>
      <b-col
        class="mb-1 mb-sm-0"
        cols="12"
        md="4">
        <DatePickerFilter
          v-model="filter.endDate"
          label="End Date" />
      </b-col>
      <b-col
        sm="12"
        md="4"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="w-100 mt-2"
          variant="primary"
          @click="fetchFilter()"
        >
          <feather-icon
            icon="SearchIcon"
            class="mr-50 mr-md-0 mr-lg-0 mr-xl-50"
          />
          <span class="align-middle">Search</span>
        </b-button>
      </b-col>
    </b-row>
    <b-card>
      <TableTop
        v-model="pagination.limit"
        label="Export"
        icon="FileTextIcon"
        variant="success"
        @active-sidebar="exportCSV()"
        @input="fetchFilter()"
      />
      <b-table
        striped
        hover
        responsive
        :items="items"
        :fields="fields"
        @row-clicked="rowClick($event)">
        <template #cell(name)="data">
          <div
            v-if="data.item.name"
            style="min-width: 16rem;">
            {{ data.item.name }}
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #cell(caseStatus.open)="data">
          <div style="min-width: 8rem;">
            {{ data.item.caseStatus.open }}
          </div>
        </template>
        <template #cell(sla.inSla)="data">
          <div style="min-width: 6rem;">
            {{ data.item.sla.inSla }}
          </div>
        </template>
        <template #cell(sla.overSla)="data">
          <div style="min-width: 6rem;">
            {{ data.item.sla.overSla }}
          </div>
        </template>
      </b-table>
    </b-card>
    <b-col
      cols="12"
      sm="12"
      class="d-flex align-items-center justify-content-center justify-content-sm-end">
      <b-pagination
        v-model="pagination.page"
        :total-rows="pagination.totalDocs"
        :per-page="pagination.limit"
        first-number
        last-number
        class="mb-0 mt-1 mt-sm-0"
        prev-class="prev-item"
        next-class="next-item"
        @change="showCurrentPage($event)"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18" />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18" />
        </template>
      </b-pagination>
    </b-col>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import SearchTextInput from '@/components/Filter/SearchTextInput.vue'
import FilterDropdown from '@/components/Filter/FilterDropdown.vue'
import DatePickerFilter from '@/components/Filter/DatePickerFilter.vue'
import ReportProvider from '@/resources/ReportProvider'
import UserTeamProvider from '@/resources/UserTeamProvider'
import UserGroupProvider from '@/resources/UserGroupProvider'
import TableTop from './components/TableTop.vue'
// import TicketFilter from '@/components/TicketFilter.vue'
// import TicketAdd from '@/components/Ticket/TicketAdd.vue'
// import TicketDetail from '@/components/Ticket/TicketDetail.vue'
const ReportService = new ReportProvider()
const UserTeamService = new UserTeamProvider()
const UserGroupService = new UserGroupProvider()

export default {
  components: {
    TableTop,
    DatePickerFilter,
    SearchTextInput,
    FilterDropdown
  },
  data () {
    return {
      isDeleteModalActive: false,
      filter: {
        searchCodeAndName: '',
        userTeamId: '',
        userGroupId: '',
        startDate: dayjs().format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD')
      },
      pagination: {
        page: 1,
        limit: 10,
        totalDocs: 10
      },
      fields: [
        { key: 'name', label: 'ช่องทางการแจ้งเรื่อง', sortable: false },
        { key: 'total', label: 'Total', sortable: true },
        { key: 'caseStatus.closed', label: 'Closed', sortable: true },
        { key: 'caseStatus.closedPercent', label: '%', sortable: true },
        { key: 'caseStatus.open', label: 'In Progress', sortable: true },
        { key: 'caseStatus.openPercent', label: '%', sortable: true },
        { key: 'caseStatus.void', label: 'Cancel', sortable: true },
        { key: 'caseStatus.voidPercent', label: '%', sortable: true },
        { key: 'sla.inSla', label: 'In SLA' },
        { key: 'sla.inSlaPercent', label: '%' },
        { key: 'sla.overSla', label: 'Over SLA' },
        { key: 'sla.overSlaPercent', label: '%' }
      ],
      items: [],
      selectedItem: {},
      initialTicketType: {},
      deleteItem: {},
      ticketType: [],
      startDate: '',
      endDate: '',
      options: {
        userTeam: [],
        userGroup: []
      }
    }
  },
  created () {
    this.getReport()
    this.getAllUserTeam()
    this.getAllUserGroup()
  },
  methods: {
    async getReport () {
      try {
        const data = await ReportService.summaryByChannelPaginate(this.pagination.page, this.pagination.limit, this.filter)
        this.setPagination(data.page, data.totalDocs)
        this.items = [...data.data]
      } catch (error) {
        console.error(error)
      }
    },
    async getAllUserTeam () {
      try {
        const { data } = await UserTeamService.findAllTeamName()
        if (data) {
          this.options.userTeam = data
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getAllUserGroup () {
      try {
        const { data } = await UserGroupService.findAllGroupCode()
        if (data) {
          this.options.userGroup = data
        }
      } catch (error) {
        console.error(error)
      }
    },
    exportCSV () {
      const items = this.items.map((item) => ({
        'ช่องทางการแจ้งเรื่อง': `${item.name}`,
        'Total': item?.total,
        'Closed': item?.Status?.closed || 0,
        '% (Closed)': item?.caseStatus?.closedPercent || 0,
        'In Progress': item?.caseStatus?.open || 0,
        '% (In Progress)': item?.caseStatus?.openPercent || 0,
        'Cancel': item?.caseStatus?.void || 0,
        '% (Cancel)': item?.caseStatus?.voidPercent || 0,
        'In SLA': item?.sla?.inSla || 0,
        '% (In SLA)': item?.sla?.inSlaPercent || 0,
        'Over SLA': item?.sla?.overSla || 0,
        '% (Over SLA)': item?.sla?.overSlaPercent || 0
      }))
      const replacer = (key, value) => (value === null ? '' : value) // specify how you want to handle null values here
      const header = Object.keys(items[0])
      const csv = [
        header.join(','), // header row first
        ...items.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','))
      ].join('\r\n')
      const universalBOM = '\uFEFF'
      const csvContent = `data:text/csv; charset=utf-8,${encodeURIComponent(universalBOM + csv)}`
      // const encodedUri = encodeURIComponent(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', csvContent)
      link.setAttribute('download', `REPORT_SUMMARY_BY_AGENT_${new Date().toLocaleString('en-US')}.csv`)
      document.body.appendChild(link)
      link.click()
    },
    rowClick () {
      // this.selectedItem = { ...val }
      // this.initialTicketType = { ...val }
      // this.$router.push(`/case/detail/${val.id}`)
    },
    fetchFilter () {
      this.setPagination(1)
      this.getReport()
    },
    showCurrentPage (page) {
      this.setPagination(page)
      this.getReport()
    },
    setPagination (page, totalDocs) {
      this.pagination.page = page || this.pagination.page
      this.pagination.totalDocs = totalDocs || this.pagination.totalDocs
    }
  }
}
</script>

<style lang="scss" scoped>
.v-select {
  background: white;
}
.card-body {
  padding: 0;
}
::v-deep input.vs__search::placeholder {
  color: #B9B9C3;
}
</style>
